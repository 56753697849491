<template>
  <v-card
    class="mx-n3 mx-sm-16 px-0 px-sm-4 px-md-8 flex-fill"
    max-width="500"
    flat
    tile
    outlined
  >
    <v-card-title class="my-3 my-sm-6 my-md-12 text-h4 font-weight-bold justify-center">
      {{ gc_langText.join.title[gc_lang] }}
    </v-card-title>

    <v-card-text class="py-0">
      <!--  form 박스  -->
      <v-form
        ref="form"
        v-model="formValid"
      >

        <!--  회원 타입  -->
        <v-radio-group
          v-model="form.userType"
          class="mt-n4 mt-md-n8"
          :rules="[gc_rules.required]"
          :disabled="requesting"
          row
        >
          <v-radio
            class="mr-0"
            :label="gc_langText.common.account.usertype['1'][gc_lang]"
            value="1"
          />
          <v-radio
            class="mr-0 ml-4"
            :label="gc_langText.common.account.usertype['0'][gc_lang]"
            value="0"
          />
          <v-radio
            class="mr-0 ml-4"
            :label="gc_langText.common.account.usertype['2'][gc_lang]"
            value="2"
          />
        </v-radio-group>

        <!--  이름  -->
        <v-text-field
          class="rounded-0"
          v-model="form.name"
          :label="gc_langText.common.account.name[gc_lang]"
          :rules="[gc_rules.required]"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  이메일 입력  -->
        <v-row class="mb-4">

          <!--  이메일 프론트  -->
          <v-col
            class="pr-0"
            cols="6"
          >
            <v-text-field
              class="rounded-0"
              v-model="form.mailFront"
              :label="gc_langText.common.account.mail[gc_lang]"
              :rules="[gc_rules.required, gc_rules.mailFront]"
              :disabled="requesting"
              validate-on-blur
              outlined
              dense
            >
              <template v-slot:append-outer>
                <v-icon>mdi-at</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <!--  이메일 백  -->
          <v-col cols="6">

            <!--  입력란  -->
            <v-text-field
              ref="mailBack"
              class="rounded-0"
              v-model="form.mailBack"
              :rules="[gc_rules.required, gc_rules.mailBack]"
              :placeholder="gc_langText.common.account.directInput[gc_lang]"
              :disabled="requesting || !!selectedMail"
              validate-on-blur
              outlined
              dense
            />

            <!--  선택란  -->
            <v-select
              class="rounded-0"
              v-model="selectedMail"
              @change="gm_form_selectMail"
              :items="gc_form_emailList"
              :disabled="requesting"
              hide-details
              outlined
              dense
            />

          </v-col>

        </v-row>

        <!--  form 박스  -->
        <v-form
          ref="pwForm"
          v-model="pwFormValid"
        >

          <!--  비밀번호  -->
          <v-text-field
            class="rounded-0"
            v-model="form.pw"
            :label="gc_langText.common.account.pw[gc_lang]"
            @focus="form.pw = ''"
            @input="gm_form_isPwEqual"
            @click:append="show.pw = !show.pw"
            :rules="[gc_rules.required, gc_rules.pwLength, gc_rules.pwEqual]"
            :append-icon="show.pw ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show.pw ? 'text' : 'password'"
            :disabled="requesting"
            validate-on-blur
            outlined
            dense
          />

          <!--  비밀번호 확인  -->
          <v-text-field
            class="rounded-0"
            v-model="form.pwr"
            :label="gc_langText.common.account.pwr[gc_lang]"
            @focus="form.pwr = ''"
            @input="gm_form_isPwEqual"
            @click:append="show.pwr = !show.pwr"
            :rules="[gc_rules.required, gc_rules.pwLength, gc_rules.pwEqual]"
            :append-icon="show.pwr ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show.pwr ? 'text' : 'password'"
            :disabled="requesting"
            validate-on-blur
            outlined
            dense
          />

        </v-form>

        <!--  소속  -->
        <v-text-field
          class="rounded-0"
          v-model="form.affiliation"
          :label="gc_langText.common.account.affiliation[gc_lang]"
          :rules="[gc_rules.required]"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  진료과  -->
        <v-text-field
          v-if="form.userType === '1'"
          class="rounded-0"
          v-model="form.medicalDepartment"
          :label="gc_langText.common.account.medicalDepartment[gc_lang]"
          :rules="[gc_rules.required]"
          :disabled="requesting"
          validate-on-blur
          outlined
          dense
        />

        <!--  휴대폰 번호  -->
        <v-text-field
          class="rounded-0"
          v-model="form.phone"
          @input="gm_form_onlyNumber"
          @keypress.enter="getAuthCode"
          :rules="[gc_rules.required, gc_rules.phone]"
          :label="gc_langText.common.account.cellPhoneNumber[gc_lang]"
          :placeholder="gc_langText.common.account.withOutText[gc_lang]"
          :disabled="phoneNumDisabled"
          validate-on-blur
          outlined
          dense
        >
          <template v-slot:append-outer>
            <v-btn
              class="mt-n2 c-h-40"
              elevation="0"
              color="cyan"
              @click="getAuthCode"
              :disabled="sendDisabled"
              :dark="!sendDisabled"
              :loading="phoneAuth.sendRequesting"
              :style="form.phoneAuth ? 'visibility: hidden;' : ''"
              tile
            >
              <template v-slot:default>
                <span v-if="phoneAuth.resendSeconds > 0">{{ `${countResend}\n${gc_langText.join.btn.resendCode[gc_lang]}` }}</span>
                <span v-else-if="phoneAuth.sent">{{ gc_langText.join.btn.resendCode[gc_lang] }}</span>
                <span v-else>{{ gc_langText.join.btn.sendCode[gc_lang] }}</span>
              </template>
            </v-btn>
          </template>
        </v-text-field>

        <!--  인증 번호  -->
        <v-text-field
          ref="authNum"
          class="rounded-0"
          v-model="phoneAuth.inputCode"
          validate-on-blur
          :label="gc_langText.join.authCode[gc_lang]"
          @keypress.enter="requestAuth"
          @focus="phoneAuth.validWrongMsg = ''"
          :disabled="phoneCodeDisabled"
          :error-messages="phoneAuth.validWrongMsg"
          outlined
          dense
        >
          <template v-slot:append>
            <span
              v-if="!form.phoneAuth && phoneAuth.validSeconds"
              class="pl-3 pt-1 red--text"
            >{{ countAuth }}</span>
          </template>
          <template v-slot:append-outer>
            <v-btn
              class="mt-n2 c-h-40"
              elevation="0"
              color="cyan"
              @click="requestAuth"
              :disabled="authDisabled"
              :dark="!authDisabled"
              :loading="phoneAuth.validRequesting"
              tile
            >
              <template v-slot:default>
                <span v-if="form.phoneAuth">{{ gc_langText.join.btn.authed[gc_lang] }}</span>
                <span v-else>{{ gc_langText.join.btn.authCheck[gc_lang] }}</span>
              </template>
            </v-btn>
          </template>
        </v-text-field>

        <!--  이용약관 동의 채크박스  -->
        <v-checkbox
          v-model="form.checkbox.require1"
          class="mt-0 pt-0"
          :rules="[gc_rules.required]"
          :label="gc_langText.join.btn.checkboxText1[gc_lang]"
          @click="termShow.require1 = true"
          readonly
          dense
        />

        <!--  개인정보 처리방침 동의 채크박스  -->
        <v-checkbox
          v-model="form.checkbox.require2"
          class="mt-0 pt-0"
          :rules="[gc_rules.required]"
          :label="gc_langText.join.btn.checkboxText2[gc_lang]"
          @click="termShow.require2 = true"
          readonly
          dense
        />

      </v-form>
    </v-card-text>

    <v-card-actions class="px-4 pt-0 flex-column">

      <v-btn
        ref="submit"
        class="text-h6"
        @click="submit"
        elevation="0"
        color="cyan"
        :dark="!requesting"
        :disabled="requesting"
        :loading="requesting"
        block
        large
        tile
      >
        {{ gc_langText.join.btn.createAccount[gc_lang] }}
      </v-btn>

      <v-btn
        class="my-6 ml-0 text-h6"
        elevation="0"
        to="/"
        block
        large
        outlined
        tile
      >
        {{ gc_langText.common.etc.goToHomeBtn[gc_lang] }}
      </v-btn>

    </v-card-actions>

    <term-dialog
      :_show="termShow.require1 || termShow.require2"
      :mode="termShow.require1 ? 1 : 2"
      @close="termClose"
    />
  </v-card>
</template>

<script>
import TermDialog from "../components/join/TermDialog"
export default {
  name: "Join",

  components: {
    TermDialog
  },

  data: () => ({
    // 메일 도메인 선택 값
    selectedMail: null,

    // 폼 입력 데이터
    form: {
      userType: '',
      name: '',
      mailFront: '',
      mailBack: '',
      pw: '',
      pwr: '',
      phone: '',
      phoneAuth: false, // 폰 인증여부
      affiliation: '', // 소속
      medicalDepartment: '', // 진료과
      checkbox: {
        require1: false, // 이용약관
        require2: false, // 개인정보 처리
        is_marketing: false, // 마케팅
        is_advertising: false // 광고수신
      }
    },

    // 휴대폰 인증번호 관련 로직 참고용 객체
    phoneAuth: {
      inputCode: '', // 사용자 입력 인증 코드
      responseCode: '', // API 결과 원본 인증 코드
      sendRequesting: false, // 인증번호 전송 요청중 상태
      sent: false, // 인증번호를 전송 요청한 적이 있다
      resendSeconds: 0, // 인증번호 재전송 가능까지 대기 시간 (초)
      resendSecondsDefault: 30, // 인증번호 재전송 가능까지 대기 시간 기준 값 (초)
      validSeconds: 0, // 인증번호 인증확인 유효시간 (초)
      validRequesting: false, // 인증번호 인증확인 요청중 상태
      validWrongMsg: '' // 인증번호 확인 결과 틀렸을 경우 수동 텍스트 입력
    },

    // 비밀번호 입력란 보임 여부
    show: {
      pw: false,
      pwr: false
    },

    // 폼 내부 입력 값들 유효 상태
    formValid: true,

    // 비밀번호 폼 입력 값들 유효 상태
    pwFormValid: true,

    // 요청 상태
    requesting: false,

    // 약관 동의 팝업
    termShow: {
      require1: false, // 이용약관
      require2: false, // 개인정보 처리방침
    }
  }),

  computed: {
    // 휴대폰번호 입력란 비활성화
    phoneNumDisabled() {
      // 재전송 대기중일 경우
      const wait  = this.phoneAuth.resendSeconds > 0
      // 인증한 경우
      const authed = this.form.phoneAuth
      // 인증 번호 요청중일 경우
      const sendRequesting = this.phoneAuth.sendRequesting
      // 인증 확인 요청중일 경우
      const validRequesting = this.phoneAuth.validRequesting

      return this.requesting || wait || authed || sendRequesting || validRequesting
    },
    // 인증번호 전송버튼 비활성화
    sendDisabled() {
      // 핸드폰 번호가 10 ~ 11 자리가 아닐 경우
      const valid = this.form.phone.length < 10 || this.form.phone.length > 11

      return this.phoneNumDisabled || valid
    },

    // 휴대폰 인증 코드 입력란 비활성화
    phoneCodeDisabled() {
      // 인증번호 입력 대기시간이 0초 이하일 경우
      const notWait  = this.phoneAuth.validSeconds <= 0
      // 인증한 경우
      const authed = this.form.phoneAuth
      // 인증 확인 요청중일 경우
      const validRequesting = this.phoneAuth.validRequesting

      return this.requesting || notWait || authed || validRequesting
    },

    // 휴대폰 인증 버튼 비활성화
    authDisabled() {
      // 입력된 인증코드 값이 없다
      const inputCode  = !this.phoneAuth.inputCode

      return this.phoneCodeDisabled || inputCode
    },

    // 재전송까지 남은 시간 (초) 카운트 텍스트
    countResend() {
      const num = this.phoneAuth.resendSeconds
      const min = Math.floor(num / 60)
      let sec = (num % 60).toString()

      sec = sec.length === 2 ? sec : `0${sec}`

      return `${min}:${sec}`
    },

    // 인증 유효까지 남은 시간 (초) 카운트 텍스트
    countAuth() {
      const num = this.phoneAuth.validSeconds

      if (0 >= num) { return '' }

      const min = Math.floor(num / 60)
      let sec = (num % 60).toString()

      sec = sec.length === 2 ? sec : `0${sec}`

      return `${min}:${sec}`
    }
  },

  methods: {
    // 폼 입력값 전송
    submit() {
      const it = this
      const phoneAuth = this.phoneAuth
      const lang = this.gc_lang
      const valid = this.gc_langText.common.valid
      const texts = this.gc_langText.join

      this.$refs.form.validate()
      this.$refs.pwForm.validate()
      // 폰 인증 여부 발리데이션
      phoneAuth.validWrongMsg = this.form.phoneAuth ? '' : valid.phoneAuth[lang]

      // ( 일반 입력란 && 비밀번호 입력란 && 이메일 인증 ) 모두 이상 없을 경우
      if (this.formValid && this.pwFormValid && !phoneAuth.validWrongMsg) {
        const params = {
          email: `${this.form.mailFront}@${this.form.mailBack}`,
          password: this.form.pw,
          name: this.form.name,
          phone: this.form.phone,
          department: this.form.medicalDepartment,
          position: this.form.affiliation,
          type: this.form.userType,
          is_agree_terms: this.form.checkbox.require1 ? '1' : '0',
          is_agree_terms_privacy: this.form.checkbox.require2 ? '1' : '0',
          is_marketing: this.form.checkbox.is_marketing,
          is_advertising: this.form.checkbox.is_advertising
        }

        // 가입자 타입이 의료인이 아닐 경우, 파라미터에서 진료과 삭제
        if (params.type !== '1') {
          delete params.department
        }

        this.requesting = true

        this.api_signUp(params).then( result => {
          const state = result.data.code

          if (state === 200) {
            it.gm_alertOpen({
              text: texts.completeCreateAccount[lang],
              callback: () => it.$router.push(({ name: 'Home' }))
            })
          } else if (state === 410) { // 이미 등록된 이메일일 경우
            it.gm_alertOpen({
              text: texts.alreadyExist[lang],
            })
          } else {
            throw state
          }
        }).catch(error => {
          it._api_error(`code: ${error}\nat: api_signUp`)
        }).finally(() => {
          if (it.requesting !== undefined) {
            it.requesting = false
          }
        })
      }
    },

    // 인증번호 받기
    getAuthCode() {
      this.api_sendInspectNumber()
    },

    // 인증번호 유효성 검사 요청
    requestAuth() {
      this.api_checkInspectNumber()
    },

    // 인증번호 재전송 시간 (초) 카운트
    intervalResend() {
      // 카운트가 필요 없을 경우 리턴
      if (this.phoneAuth.resendSeconds <= 0) { return }

      this.phoneAuth.resendSeconds--
      setTimeout(this.intervalResend, 1000)
    },

    // 인증번호 인증 유효 시간 (초) 카운트
    intervalAuth() {
      this.phoneAuth.validSeconds--

      // 카운트가 필요 없을 경우 리턴
      if (this.phoneAuth.validSeconds <= 0) {
        const lang = this.gc_lang
        const text = this.gc_langText.common.valid.phoneAuthTimeout[lang]

        // 카운트 setTimeout 이 실행되는 동안 인증된 경우는 '' 아닐 경우 안내문구 표시
        this.phoneAuth.validWrongMsg = this.form.phoneAuth ? '' : text
        return
      }

      setTimeout(this.intervalAuth, 1000)
    },

    // 약관 팝업 닫기
    termClose(mode = 1, checkbox = {}) {
      // mode: 1 === 이용약관
      // mode: 2 === 개인정보 처리방침 / 마케팅 / 광고수신
      this.termShow[`require${mode}`] = false

      const validRequire = typeof(checkbox[`require${mode}`]) === 'boolean'
      this.form.checkbox[`require${mode}`] = validRequire ? checkbox[`require${mode}`] : false

      // 모드 2 일 때만
      if (mode === 2) {
        this.form.checkbox.is_marketing = checkbox.is_marketing
        this.form.checkbox.is_advertising = checkbox.is_advertising
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.c-wh-auto
  height: auto !important
  min-width: auto !important
.c-h-40
  height: 40px !important
  white-space: pre
.test
  position: absolute
  top: 100%
  left: 0
  right: 0
  text-align: center
  padding: .25rem
</style>