<template>
  <v-dialog
    v-model="show"
    :width="780"
    :fullscreen="gc_bp.sm || gc_bp.xs"
    persistent
    scrollable
  >

    <v-card tile>

      <!--  이용약관 및 개인정보처리 동의서 본문  -->
      <v-card-text
        id="terms_body"
        ref="terms_body"
        class="px-2 px-sm-4 px-md-6"
      >
        <v-form
          ref="form"
          v-model="formValid"
        >

          <!--  이용 약관  -->
          <template v-if="mode === 1">
            <h1><u>VUNO Academy 서비스 이용 약관</u></h1>
            <p class="c-p">본 <b>VUNO Academy</b> 서비스 이용약관(이하 “<b>본 약관</b>”)은 주식회사 뷰노(이하 “<b>회사</b>”)가 <b>vuno-academy.com</b> (이하 “<b>본 사이트</b>”)를 통하여 의료전문가등을 위한 정보를 온라인으로 제공함에 따른 이용조건 및 절차에 관한 기본사항을 정하는데 목적이 있습니다.</p>

            <h2>제1조	(용어의 정의)</h2>
            <ol>
              <li>
                <span>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</span>
                <ol>
                  <li>"<b>서비스</b>"라 함은 본 사이트에서 제공되는 웨비나 중계영상 시청, 웨비나 영상 다시보기, 웨비나 초청, 사내 직원 대상 교육영상 다시보기 서비스 등을 말합니다.</li>
                  <li>"<b>회원</b>"이라 함은 회사가 제공하는 서비스를 이용하기 위해 필요한 아이디(ID)와 비밀번호(PASSWORD)를 설정하는 등 소정의 절차를 거쳐 서비스 이용약관에 따라 서비스를 제공받는 자를 말합니다.</li>
                  <li>"아이디(ID)"라 함은 회원식별과 회원의 서비스 이용을 위하여 회원 본인이 설정하며 회사가 승인하는 인식문자를 말합니다.</li>
                  <li>"비밀번호(PASSWORD)"라 함은 회원의 비밀보호 및 회원 본인임을 확인하고 서비스에 제공되는 각종 정보의 보안을 위해 회원 자신이 설정한 암호 문자를 말합니다.</li>
                </ol>
              </li>
              <li>본 약관에서 사용하는 용어의 정의는 제1항 각 호에서 정하는 것을 제외하고는 관계법령 및 기타 일반적인 상관례에 의합니다.</li>
            </ol>


            <h2>제2조	(정보의 보호)</h2>
            <p>회원의 개인정보는 개인정보보호법 등 관련법률이 정하는 바에 따라 엄격히 관리되며, 회원은 원하지 않는 경우 회사가 제공하는 정보의 수신을 거부할 수 있습니다.</p>

            <h2>제3조	(서비스의 제공 및 제한)</h2>
            <ol>
              <li>회원은 회사가 제공하는 서비스의 이용이 가능합니다. 다만, 회원의 자격(의료인/비의료인/사내직원)에 따라 제공되는 서비스의 종류가 달라질 수 있습니다.</li>
              <li>회사는 서버 점검∙관리, 서비스 개선, 기타 합리적인 필요에 따라 본 사이트의 기능을 포함한 사이트의 모든 사항을 종료, 변경, 중단, 정지시킬 수 있습니다.</li>
              <li>회사는 서버 점검∙관리, 서비스 개선, 기타 합리적인 필요에 따라 본 사이트의 특정 기능이나 서비스를 제한할 수 있으며, 본 사이트의 전부 또는 일부에 대한 회원의 접근을 제한하고, 회원이 본 사이트를 사용하지 못하도록 할 수 있습니다. </li>
              <li>1년 이상 장기 미 이용 회원의 경우 안내 메일 발송 후 서비스 사용을 제한합니다. 서비스 별로 휴면기준 및 조치는 상이하며, 개별서비스의 약관 및 이용안내, 공지사항에 준합니다.</li>
            </ol>

            <h2>제4조	(회사의 의무)</h2>
            <ol>
              <li>회사는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.</li>
              <li>회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖추어야 하며 개인정보 처리방침을 공시하고 준수합니다.</li>
              <li>본 사이트는 다양한 주제에 대해 의견을 게재하고 다른 회원과 공유할 수 있는 영역을 포함하고 있습니다. 회원은 본 사이트에서 또는 본 사이트를 통해 부당, 교란, 중상, 불법, 공격, 협박, 음란의 내용 또는 제3자의 저작권, 상표권, 영업 비밀, 프라이버시 또는 기타 고유한 권리 또는 재산권을 침해하는 내용, 또는 형사상 범죄를 구성하거나 민사상 책임을 야기하거나 기타 법률 또는 규정을 위반할 수 있는 내용, 또는 차별, 인종차별, 성차별, 혐오 등의 내용을 포함하나 이에 국한되지 않는 부적절한 내용, 또는 의료기구의 무단 사용 또는 타인에 대한 신체적 위해 등을 포함하나 이에 국한되지 않는 불법 행위를 조장하는 내용을 게재 또는 전파하지 않는데 동의합니다. 회사는 부적절하다고 간주되는 내용은 자체 재량으로 회원에게 사전 고지 없이 삭제할 권리를 보유하나, 이를 반드시 삭제할 의무는 없습니다. 회원은 자신의 책임하에 의견을 게시하며, 회사는 이에 대한 여하한 책임을 부담하지 않습니다.</li>
            </ol>

            <h2>제5조	(회원의 의무)</h2>
            <ol>
              <li>
                <span>회원은 서비스를 이용할 때 다음 각호의 행위를 하지 않아야 합니다.</span>
                <ol>
                  <li>다른 회원의 아이디(ID) 및 비밀번호(PASSWORD)를 부정하게 사용하거나, 자신의 아이디(ID) 및 비밀번호 (PASSWORD)를 타인으로 하여금 사용하도록 하게 하는 행위</li>
                  <li>일반 회원에게 접근이 금지된 본 사이트 및 그 일부 내용에 대해 무단으로 접근할 수 있는 권한을 확보하기 위한 시도</li>
                  <li>서비스를 이용하여 얻은 정보를 회원의 개인적인 이용 외에 복사, 가공, 2 차적 저작 등을 통하여 복제, 공연, 방송, 전시, 배포, 출판 등에 사용하거나 제3자에게 제공하는 행위</li>
                  <li>타인의 명예를 손상시키거나 불이익을 주는 행위</li>
                  <li>회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위</li>
                  <li>공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위 또는 범죄와 결부된다고 객관적으로 인정되는 행위</li>
                  <li>서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염자료를 등록 또는 유포하는 행위</li>
                  <li>본 사이트, 서비스, 시스템 리소스, 계정, 본 사이트 또는 본 사이트와 연계 또는 링크된 웹사이트의 안정적 운영 및 보안에 지장을 주거나, 방해하거나, 오용하거나, 다른 회원에게 지장을 주거나 방해하거나, 수신자의 의사에 반하여 광고성 정보, 정크메일, 기타 요청하지 않은 다량의 이메일을 전송하는 행위</li>
                  <li>특정 조건이나 상황에 있는 환자에게 적용할 목적으로 특정한 의료적 조언, 의견, 진단, 치료 정보를 모색, 제공, 확보하기 위해 본 사이트를 사용하는 행위.</li>
                  <li>기타 관계법령에 위배되는 행위</li>
                </ol>
              </li>
              <li>회원은 특정 환자의 신분, 또는 특정 환자를 알 수 있는 환자의 의료 정보나 기타 정보를 논의 또는 언급하지 않는데 명시적으로 동의합니다. 또한 회원은 식약처 또는 다른 규제 기관에서 승인되지 않은 의료 제품의 “허가 외” 사용을 논의, 보증, 언급하지 않는데 동의합니다.</li>
              <li>아이디(ID)와 비밀번호(PASSWORD) 관리에 관한 일체의 책임은 회원 본인에게 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호(PASSWORD)를 제3자에게 대여 또는 양도하거나 이와 유사한 행위를 하여서는 안되며, 아이디(ID)와 비밀번호(PASSWORD)의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있습니다. 회사가 정한 소정의 인증 방식을 이용할 경우, 해당 인증방식의 비밀번호 관리에 관한 일체의 책임은 해당 인증 약관에 준합니다. 회원은 자신의 아이디(ID)가 부정하게 사용된 것을 인지한 경우, 즉시 자신의 비밀번호(PASSWORD)를 변경하고 그 사실을 회사에 통보하여야 합니다. 회원은 본인이 가입 시 등록한 개인정보에 변경사항이 있을 때에는 홈페이지 또는 고객센터를 통해 지체 없이 회사에 통보하여야 합니다. 회원이 본항의 통지를 하지 않음으로써 발생하는 손해에 대하여 회사는 책임지지 않습니다.</li>
              <li>회원은 회사의 사전승낙 없이 서비스를 이용하여 영업활동을 할 수 없으며, 그 영업 활동으로 인한 결과에 대하여 회사는 책임을 지지 않습니다.</li>
              <li>회원은 이 약관 및 관계법령에서 규정한 사항과 서비스 이용안내 또는 주의사항을 성실히 준수하여야 합니다.</li>
              <li>회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한사항을 준수하여야 합니다.</li>
              <li>회원은 회사의 사전동의 없이 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.</li>
            </ol>

            <h2>제6조	(계약해지 및 이용제한)</h2>
            <ol>
              <li>회원이 서비스 이용계약을 해지하고자 할 경우에는 본인이 회사 홈페이지 또는 회사가 정한 별도의 방법을 이용하여 회사에 해지 신청을 하여야 합니다. 이 경우 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 삭제됩니다.</li>
              <li>회사는 보안 및 아이디 정책, 서비스의 원활한 제공 등과 같은 이유로 제8조의 방법으로 고지를 통하여 회원의 아이디(ID)변경을 요구하거나 변경할 수 있습니다.</li>
              <li>
                <span>회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우에는 즉시 서비스의 이용을 중지하고 서비스 이용계약이 해지될 수 있음을 알려드리며, 통지일로부터 10영업일이 경과한 경우 서비스 이용계약을 해지할 수 있습니다.</span>
                <ol>
                  <li>아이디(ID)와 비밀번호(PASSWORD)등 회원 고유정보를 타인에게 누설하거나 타인의 아이디(ID) 및 비밀번호(PASSWORD)를 도용한 경우</li>
                  <li>바이러스 프로그램 유포, 해킹 등 부정한 방법을 사용하여 서비스 운영을 고의로 방해하여 서비스 또는 회사에 피해가 발생할 가능성이 상당한 경우</li>
                  <li>가입시 허위의 정보를 기재하거나 필수 기재사항을 기재하지 않아 계약 목적달성이 어려운 경우</li>
                  <li>동일 회원이 다른 ID로 이중 등록을 한 경우</li>
                  <li>공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우</li>
                  <li>회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는 실행하는 경우</li>
                  <li>타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</li>
                  <li>서비스의 안정적 운영을 방해할 목적으로 정보를 전송하거나 광고성 정보를 전송하는 경우</li>
                  <li>통신설비의 오 동작이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
                  <li>회사, 다른 회원 또는 제 3 자의 지적재산권을 침해하는 경우</li>
                  <li>회사의 서비스정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우</li>
                  <li>서비스 이용약관을 포함하여 기타 회사가 정한 이용조건 및 관계법령을 위반해 더 이상의 서비스 제공이 어렵다고 판단되는 경우</li>
                </ol>
              </li>
              <li>전 3 항에 해당하는 행위를 한 회원은 이로 인해 회사 또는 다른 회원에게 발생한 손해를 배상할 책임이 있습니다.</li>
              <li>전 3 항의 회사 조치에 대하여 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있고, 이의가 정당하다고 인정하는 경우 회사는 해당 서비스의 이용을 재개합니다.</li>
            </ol>

            <h2>제7조	(게시물 등의 관리)</h2>
            <p>회사는 서비스를 제공하는 인터넷사이트의 원활한 관리·감독을 위하여 필요한 경우, 게시되는 내용물에 대해 추가·수정·삭제할 수 있으며, 회원에게 별도 통지 없이 서비스의 개편 및 내용의 추가·수정·삭제를 할 수 있습니다.</p>

            <h2>제8조	(링크사이트에 대한 책임)</h2>
            <ol>
              <li>회사는 회원에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 그러나 이 경우 회사는 링크된 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받은 서비스나 자료의 유용성에 대해 회사가 아무런 보증도 하지 않고, 그에 대해 책임이 없습니다.</li>
              <li>회원은 링크된 외부사이트의 서비스나 자료를 신뢰함으로써 야기되거나 야기되었다고 주장되는 어떠한 손해나 손실에 대해서도 회사에 책임이 없음을 인정하고 이에 동의합니다.</li>
            </ol>

            <h2>제9조	(저작권등의 귀속 및 이용제한)</h2>
            <ol>
              <li>디자인, 텍스트, 소프트웨어, 이미지, 형태, 도안, 기타 파일 등을 포함하나 이에 국한되지 않는 본 사이트에 있는 모든 컨텐츠, 그리고 그 선정과 배열(이하 “컨텐츠”)은 회사가 소유한 저작권이거나, 그 라이선스 소유자의 고유 자산입니다. 회원은 회사의 사전 서면 승인 없이 회사가 제공하는 컨텐츠의 전부 또는 일부를 어떠한 형태나 방법으로 수정, 복사, 배포, 재생산, 리퍼블리싱, 다운로드, 디스플레이, 포스팅, 전송 또는 판매할 수 없습니다.</li>
              <li>회원은 전자적 또는 기타 방법으로 본 사이트로부터 어떠한 데이터나 데이터 필드(고객정보를 포함하나 이에 국한되지 아니함)를 조직적으로 추출, 수집, 획득하지 않는 데 동의합니다.</li>
              <li>본 사이트에 있는 모든 상표는 회사 또는 그 계열사 또는 라이선스 소유자의 상표 또는 등록 상표로, 회사의 사전 서면 승인 없이 그 전부 또는 일부를 복제, 모방, 이용할 수 없습니다. 모든 페이지 헤더, 커스텀 그래픽, 버튼 아이콘, 스크립트는 회사 또는 그 계열사의 서비스표, 상표 또는 트레이드 드레스로, 회사의 사전 서면 승인 없이 그 전부 또는 일부를 복제, 모방, 이용할 수 없습니다. 본 사이트에 보이는 기타 상표, 등록 상표, 제품명, 회사명 또는 로고는 각각 해당 소유자의 자산입니다.</li>
              <li>회사의 제품과 프로세스는 하나 이상의 특허로 보호받으며, 기타 영업 비밀이나 재산적 권리의 적용을 받습니다. 회사가 보유한 특허상의 권리는 어떤 것이든 양도 또는 증여되지 않으며, 본 이용 약관의 조항에는 이를 함축하는 내용이 포함되어 있지 않습니다. 회원은 이러한 권리를 침해하지 않으며 회사의 제품이나 프로세스를 역컴파일, 역설계, 분해하지 않는데 동의합니다.</li>
              <li>본 사이트를 통해 사용 또는 접근가능한 어떠한 소프트웨어, 해당 소프트웨어로 만든 어떠한 파일이나 이미지, 코드, 소프트웨어에 동반된 데이터(이하 “소프트웨어”로 통칭)는 사용자가 본 사이트에 명시된 목적만을 위해 접근하고 사용하는 것으로 하되, 그러한 사용은 뷰노와 경쟁하거나 명예를 훼손하는 것이 아니어야 합니다. 뷰노는 소프트웨어에 있는 모든 지적재산권에 대해 모든 그리고 완전한 권리를 보유합니다. 사용자는 소프트웨어에서 기인한 어떠한 파생물도 배포, 판매, 수정, 역컴파일, 역설계, 분해 또는 재현하지 않는데 추가로 동의합니다.</li>
              <li>회원이 서비스 내에 게시한 게시물(회원간 전달 포함)의 저작권은 회원이 소유하며 회사는 서비스 내에 이를 게시할 수 있는 권리를 갖습니다. 회사는 게시한 회원의 동의 없이 게시물을 다른 목적으로 사용할 수 없습니다.</li>
              <li>회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램 저작권 등을 침해하더라도 이에 대한 민, 형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권, 프로그램저작권 등을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우 회원은 그로 인해 회사에 발생한 모든 손해를 부담하여야 합니다.</li>
              <li>회사는 회원이 서비스 이용계약을 해지하거나 동 계약이 적법한 사유로 해지된 경우 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다.</li>
            </ol>

            <h2>제10조	(회사 컨텐츠, 제품, 서비스 정보에 대한 제한)</h2>
            <p>본 사이트를 통해 이용가능한 데이터, 이미지, 배치, 가격 또는 주문 정보, 카탈로그 리스팅 등을 포함하나 이에 국한되지 않는 본 사이트에 있는 모든 컨텐츠는 “있는 그대로” 및 “이용가능한” 상태를 조건으로 제공됩니다.  본 사이트를 통해 이용가능한 컨텐츠, 제품 또는 서비스를 회원의 니즈에 따라 사용하는 경우 그 정확성에 대해서는 회원이 독립적으로 평가해야 합니다. 제품 및 서비스의 세부사항은 사전 고지 없이 변동될 수 있으며, 회사는 프로세스, 내용, 구성에 대한 사전 고지 없이 이를 변경할 권리를 보유합니다. 뷰노는 온라인 카탈로그에 열거된 제품이나 서비스가 주문에 맞춰 이용가능함을 보증하지 않습니다. 사이트에 있는 모든 정보는 아래 제11조 및 제12조에서 정한 면책 조항의 적용을 받습니다.</p>

            <h2>제11조	(면책)</h2>
            <ol>
              <li>본 사이트를 통해 이용가능한 컨텐츠는 “있는 그대로” 및 “이용가능한” 상태를 조건으로 제공됩니다. 회원은 본 사이트나 그 컨텐츠의 사용에 따른 위험을 전적으로 회원이 감수한다는 데 명시적으로 동의합니다. 관련 법에서 허용하는 최대한의 범위 내에서, 회사는 상업성, 특정 목적 적합성, 비침해 등을 포함하나 이에 국한되지 않는 어떠한 명시적 또는 묵시적 보장도 하지 않습니다. 회원은 본 사이트의 사용과 본 사이트를 통해 배포, 다운로드, 접근하는 모든 컨텐츠, 데이터 또는 소프트웨어와 관련해 회원이 전적으로 위험을 감수한다는 데에 명시적으로 동의합니다. 그러한 컨텐츠, 데이터 또는 소프트웨어를 다운로드 받은 결과 회원의 사업이나 컴퓨터 시스템에 대한 피해 또는 데이터 손실에 대해서는 회사의 고의 또는 중과실이 없는 한 회원이 전적으로 책임이 있다는 점을 이해하고 동의합니다.</li>
              <li>회원은 본 사이트를 통해 제3자가 제공하는 정보, 제품 또는 서비스를 회사가 통제하지 않는다는 점을 인정합니다. 서면에 의하여 명시적으로 합의된 경우를 제외하고, 회사는 본 사이트를 통해 제3자가 배포 또는 이용가능하게 만든 조언, 의견, 진술 또는 기타 컨텐츠 또는 어떠한 제품이나 서비스에 대해 그 내용의 정확성, 최신성, 완결성, 신뢰성 또는 유용성에 대해 보증이나 진술할 책임이 없습니다. 회사는 본 사이트 또는 그 컨텐츠가 회원의 요구사항을 충족한다는 점을 보증하지 않습니다. 또한 본 사이트 또는 그 컨텐츠가 중단되지 않으며, 시의적절하며, 보안상 안전하며, 에러가 없으며, 흠결이 있는 경우 수정이 된다는 것을 보증하지 않습니다. 회사는 본 사이트 또는 그 컨텐츠를 사용해 획득할 수 있는 결과에 대해 보증하지 않으며, 본 사이트를 통해 얻는 정보의 정확성, 완결성, 신뢰성에 대해서도 보증하지 않습니다.</li>
              <li>회사는 지연, 컨텐츠나 이메일의 비전송, 에러, 시스템 고장, 컨텐츠나 이메일의 전송착오, 네트워크 또는 시스템 오류, 파일 손상, 회사 및 계열사, 회사 라이선스 소유자의 부주의에 의한 서비스 중단, 또는 회원의 오류나 누락에 의한 데이터 손실 등 이를 포함하나 이에 국한되지 않는 손상에 대해 고의 또는 중과실이 없는 한 책임을 지지 않습니다. 본 약관에 특별한 규정이 없는 한 회사는 본 사이트를 통해 전송되는 정보의 기밀 유지에 대해 어떠한 보증이나 진술을 하지 않습니다.</li>
              <li>회사는 본 사이트를 통해 획득한 구두 또는 문서에 의한 조언 또는 정보는 문서에 따른 명시적인 보증을 제공하지 않습니다. 다만, 관계 법령상 특정 보증이나 조건의 제한이나 배제를 허용하지 않는 경우에는 그러하지 않습니다.</li>
            </ol>

            <h2>제12조	(책임 제한)</h2>
            <p>회사 및 그 계열사, 임직원, 대리인, 또는 협력사는 본 사이트 또는 본 사이트에 포함된 컨텐츠의 사용, 사용 불능, 무단 사용, 또는 회원의 전송이나 데이터의 변경, 송수신된 또는 송수신 되지 않은 정보로 인한 어떠한 직접, 간접, 우발, 특수, 결과적 손상 또는 이윤, 사용, 데이터 또는 기타 무형자산에 대한 손상에 대해 고의 또는 중과실이 없는 한 책임이 없으며, 회사가 그러한 손상 가능성에 대해 통지를 받았다 해도 회사는 책임을 부담하지 않습니다.</p>

            <h2>제13조	(약관의 일부무효)</h2>
            <p>본 약관은 본 사이트의 사용과 관련해 회원과 회사간에 완전한 합의를 구성합니다. 본 약관의 일부 조항이 무효 또는 집행 불가능한 것으로 판단될 경우 해당 조항은 전체와 분리될 수 있으며, 다른 조항의 유효성 또는 집행가능성에는 영향을 주지 않습니다.</p>

            <h2>제14조	(이용약관의 효력 및 변경)</h2>
            <ol>
              <li>이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을 발생합니다.</li>
              <li>이 약관의 내용은 특별한 규정이 없는 한 회사가 제공하는 인터넷서비스 화면상에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력을 발생합니다.</li>
              <li>회사는 필요하다고 인정되는 사유가 발생할 경우 이 약관의 일부 또는 전부를 변경할 수 있으며, 이 경우 회사는 변경 약관의 시행일 14일 이전에 당해 변경 내용을 회사 본 사이트 상의 공지 화면에 게시하고, 그 기간 안에 회원의 이메일 또는 서면에 의한 이의 제기가 없으면 게시 기간 종료일 익일부터 효력이 발생합니다. 회원이 1개월 이내에 이의를 제기하지 않았을 때에는 변경된 약관을 승인한 것으로 간주합니다.</li>
              <li>전항의 방법으로 변경 고지된 약관은 기존의 회원에게도 유효하게 효력이 발생하며, 변경된 약관에 동의하지 않으면 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다.</li>
            </ol>

            <h2>제15조	(약관위반시 책임)</h2>
            <p>회사와 회원은 이 약관을 위반함으로써 발생하는 모든 책임을 각자 부담하며, 이로 인하여 상대방에게 손해를 입힌 경우에는 지체 없이 배상하여야 합니다.</p>

            <h2>제16조	(분쟁해결 및 관할법원)</h2>
            <ol>
              <li>서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 우선 쌍방간에 분쟁의 해결을 위해 성실히 노력하여야 합니다.</li>
              <li>전 1 항의 노력에서도 분쟁이 해결되지 않을 경우, 관할법원은 회사 본점 소재지를 관할하는 법원을 전속관할법원으로 합니다.</li>
            </ol>

            <h2>제17조	(약관 외 준칙)</h2>
            <p>이 약관에 명시되지 않은 사항 및 각 사이트 별 해당 서비스에 대해서는 전자문서 및 전자거래기본법, 정보통신망 이용촉진 및 정보보호에 관한 법률 등 관계법령, 해당 서비스 별 관련법령 및 회사가 정한 서비스의 세부이용지침 등의 규정에 의합니다.</p>

            <v-alert
              class="mt-1 py-0"
              color="grey"
              tile
            >
              <v-checkbox
                ref="checkbox1"
                v-model="checkbox.require1"
                :rules="[gc_rules.required]"
                label="본인은 서비스 이용에 관한 설명을 모두 이해하였고, 이에 동의합니다."
              />
            </v-alert>
          </template>

          <!--  개인정보 처리방침  -->
          <template v-else>
            <h1>
              개인정보 처리에 관한 동의서
              <br>
              (VUNO Academy 회원가입용)
            </h1>
            <p>
              <b>주식회사 뷰노 귀중</b>
              <br><br>
              본인은 아래의 내용을 확인하고, 주식회사 뷰노(이하 “회사”라고 합니다)가 다음과 같이 본인의 개인정보를 처리하는 것에 동의합니다.
            </p>

            <h2>1.	개인정보의 수집 및 이용에 대한 동의(필수)</h2>
            <ul>
              <li>
                <span>필수적 수집 및 이용</span>
                <div class="c-t-wrap">
                  <table>
                    <tbody>
                    <tr>
                      <th>수집항목</th>
                      <td>
                        <ul>
                          <li>성명, 이메일 주소, 직책(의료인/비의료인/사내직원 여부), PW(비밀번호)</li>
                          <li>의료인의 경우 소속병원, 진료과</li>
                          <li>휴대전화번호</li>
                          <li>생성정보 수집 툴을 통한 수집(접속로그, 접속 IP 정보, 마지막 로그인 일시 등)</li>
                          <li>VUNO Academy 서비스 이용 내역(검색 기록, 브라우징 기록, 강의 신청내역 및 영상 시청 시간 기록, 제품·서비스에 대한 귀하의 의견·평가·반응, 민원·문의 응대 내역, 설문조사 답변 등 포함)</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>수집 및 이용목적</th>
                      <td>
                        <ul>
                          <li>VUNO Academy 서비스 이용을 위한 계정(ID)의 생성(회원가입), 계정 생성내역의 통지</li>
                          <li>VUNO Academy 서비스의 제공</li>
                          <li>고객 개인의 식별 및 회원 관리, 중복이용∙서비스의 부정 이용 방지</li>
                          <li>서비스 관련 안내 및 고지사항의 전달, 고객 불만∙문의∙민원 사항 처리</li>
                          <li>회원의 서비스 이용현황 분석, 제품 및 서비스 향상을 위한 고객 관리 및 데이터 분석</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>보유 및 이용기간</th>
                      <td>
                        상법, 국세기본법 등 관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가 있는 경우가 아닌 한, 회사는 <u>회원 탈퇴시까지</u> 귀하의 개인정보를 보유 및 이용합니다.
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>귀하는 위와 같은 개인정보의 수집 및 이용을 거부할 수 있습니다. 다만, 개인정보의 필수적 수집 및 이용에 동의하지 않을 경우 VUNO Academy의 이용이 불가능할 수 있습니다.</li>
            </ul>

            <v-alert
              class="mt-1 py-0"
              color="grey"
              tile
            >
              <v-checkbox
                ref="checkbox2"
                v-model="checkbox.require2"
                :rules="[gc_rules.required]"
                label="본인은 회사의 개인정보 수집 및 이용에 관한 설명을 모두 이해하였고, 이에 동의합니다."
              />
            </v-alert>

            <h2>2.	마케팅 목적 개인정보의 수집∙이용 및 영리목적 광고성 정보 수신에 대한 동의</h2>
            <ul>
              <li>
                <span>선택적 수집 및 이용</span>
                <div class="c-t-wrap">
                  <table>
                    <tbody>
                    <tr>
                      <th>수집항목</th>
                      <td>
                        <ul>
                          <li>성명, 의료인인 경우 근무처 정보(소속 병원 및 진료과), 비의료인인 경우 근무처 정보(소속), 이메일 주소, 전화번호</li>
                          <li>생성정보 수집 툴을 통한 수집(접속로그, 접속 IP 정보, 마지막 로그인 일시 등),</li>
                          <li>VUNO Academy 서비스 이용 내역(검색 기록, 브라우징 기록, 강의 신청내역 및 영상 시청 시간 기록, 제품·서비스에 대한 귀하의 의견·평가·반응, 민원·문의 응대 내역, 설문조사 답변 등 포함)</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>수집 및 이용목적</th>
                      <td>
                        <ul>
                          <li><u>방문, 전화, 우편(전자우편 포함), 문자/카카오톡 발송, 웨비나 진행 등을 통한 보건의료전문가를 대상으로 하는 마케팅, 회사 제품 및 서비스 안내, 시장조사, 학술대회/이벤트 안내 및 진행을 포함한 의학적 정보 전달 및 취득 활동의 수행</u></li>
                          <li>제품 및 서비스 향상을 위한 고객 관리 및 데이터 분석, 영업활동 기록, 통계 산출/분석</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>보유 및 이용기간</th>
                      <td>
                        상법, 국세기본법 등 관계 법령의 규정에 따라 귀하의 개인정보를 보존할 의무가 있는 경우가 아닌 한, 회사는 <u>수집일로부터 제1항의 보유기간까지</u> 귀하의 개인정보를 보유 및 이용합니다.
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </li>
              <li>귀하는 위와 같은 개인정보의 수집 및 이용을 거부할 수 있습니다. 다만, 동의하지 않을 경우 회사 제품의 안내 및 정보의 전달을 받지 못할 수 있습니다.</li>
            </ul>

            <v-alert
              ref="radio1"
              class="my-1 py-0"
              color="grey"
              tile
            >
              <v-radio-group
                v-model="checkbox.is_marketing"
                :rules="[gc_rules.requiredOptions]"
                dense
                :row="gc_bp.width >= 768"
              >
                <v-radio
                  on-icon="$checkboxOn"
                  off-icon="$checkboxOff"
                  label="마케팅 목적 개인정보 이용에 동의합니다."
                  value="1"
                />
                <v-radio
                  on-icon="$checkboxOn"
                  off-icon="$checkboxOff"
                  label="동의 하지 않습니다."
                  value="0"
                />
              </v-radio-group>
            </v-alert>

            <v-alert
              ref="radio2"
              class="mt-3 mb-8 py-0"
              color="grey"
              tile
            >
              <v-radio-group
                v-model="checkbox.is_advertising"
                :rules="[gc_rules.requiredOptions]"
                dense
                :row="gc_bp.width >= 768"
              >
                <v-radio
                  on-icon="$checkboxOn"
                  off-icon="$checkboxOff"
                  label="이메일, 문자, 전화 등을 통한 광고성 정보 수신에 동의합니다."
                  value="1"
                />
                <v-radio
                  on-icon="$checkboxOn"
                  off-icon="$checkboxOff"
                  label="동의 하지 않습니다."
                  value="0"
                />
              </v-radio-group>
            </v-alert>
          </template>

        </v-form>
      </v-card-text>

      <!--  버튼 그룹  -->
      <v-card-actions class="justify-center">

        <!--  버튼: 닫기  -->
        <v-btn
          class="text-h6 mr-4 c-tt-none"
          color="black"
          @click="close"
          width="128"
          height="48"
          elevation="0"
          outlined
          tile
        >{{ gc_langText.common.alert.closeText2[gc_lang] }}</v-btn>

        <!--  버튼: 서명  -->
        <v-btn
          class="text-h6 c-tt-none"
          color="cyan"
          @click="signature"
          width="128"
          height="48"
          elevation="0"
          dark
          tile
        >{{ gc_langText.join.btn.checkboxTextDetailSignature[gc_lang] }}</v-btn>

      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "TermDialog",

  props: {
    _show: {
      type: Boolean,
      default: false
    },
    mode: {
      type: Number,
      default: 1
    }
  },

  data: () => ({
    show: false,

    formValid: false,

    // 채크박스 동의 목록
    checkbox: {
      require1: null, // 이용약관
      require2: null, // 개인정보 처리방침
      is_marketing: null, // 마케팅 사용
      is_advertising: null, // 광고 수신
    }
  }),

  watch: {
    _show(val) {
      this.show = val
    },

    show(val) {
      // 페이지가 닫힐 때
      if (!val) {
        this.$emit('close', this.mode, this.checkbox)
        this.$refs.form.reset()
      }
      // 페이지가 열릴 때
      else {
        const terms_body = this.$refs.terms_body

        terms_body && setTimeout(() => terms_body.scrollTo(0,0))
      }
    }
  },

  methods: {
    // 약관 팝업 닫기
    close() {
      this.$refs.form.reset()
      this.show = false
    },

    // 약관 동의 처리
    signature() {
      this.$refs.form.validate()

      if (this.formValid) {
        this.show = false
      }
      // 채크가 누락되었을 경우 해당하는 채크박스로 스크롤
      else if (this.mode === 1 && !this.checkbox.require1) {
        this.$refs.checkbox1.$el.scrollIntoView()
      }
      else if (this.mode === 2 && !this.checkbox.require2) {
        this.$refs.checkbox2.$el.scrollIntoView()
      }
      else if (!this.checkbox.is_marketing) {
        this.$refs.radio1.$el.scrollIntoView()
      }
      else if (!this.checkbox.is_advertising) {
        this.$refs.radio2.$el.scrollIntoView()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
#terms_body
  counter-reset: section
  padding: 20px 24px
  color: #000000
  *
    word-break: keep-all !important
  h1, h2, p, li
    white-space: break-spaces
    word-break: break-word
  h1
    text-align: center
    font-size: 1.375rem
    line-height: 2rem
    padding: 0
  h2
    font-size: 1.125rem
    padding: 4px
  p
    text-indent: .5rem
  p.c-p
    padding: 2rem 0
  form
    >ol, >ul
      li
        position: relative
        text-indent: .5rem
        margin-left: .5rem
        li
          text-indent: .25rem
          margin-left: .25rem
    >ol
      counter-reset: firstOlList
      list-style: none
      >li::before
        position: absolute
        top: 2px
        right: 100%
        text-indent: 0
        font-size: .75rem
        counter-increment: firstOlList
        content: counter(firstOlList)
        border: thin solid #000000
        border-radius: 100px
        width: 1rem
        height: 1rem
        line-height: 1rem
        text-align: center
  .c-t-wrap
    overflow: auto
  table
    border: thin solid #000000
    border-collapse: collapse
    min-width: 480px
  th, td
    padding: .3rem
    border: thin solid #000000
  th
    text-align: center
    white-space: pre
    background-color: #eeeeee
</style>